import React,{useState,useEffect} from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {Telephone, Envelope, Dash} from "react-bootstrap-icons"
import { Button, Navbar, Nav,NavDropdown } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

const CustomNavbar = ({ siteTitle, pageInfo,currentPage }) => {

  const [page,setPage] = useState(currentPage);

  useEffect(() => {
      setPage(currentPage)
  },[currentPage])
  

  console.log(pageInfo)
  return (
    <>
      <Navbar bg="light"  className="navbar-style" expand="lg" id="site-navbar" style={{ padding:"0px"}}>
        {/* <Container> */}
        
        <Link to="/" className="link-no-style">
        <Navbar.Brand  as="span" ><img src="../../logo.png" style={{marginLeft:"20px"}} width="230px"/></Navbar.Brand>
      </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
          <AnchorLink to="/#home"  className="link-no-style btn btn-outline">
              <Nav.Link as="span" eventKey="home" className="nav-text">
                Home
              </Nav.Link>
            </AnchorLink>
            <NavDropdown.Divider />

            <AnchorLink to="/#about-us" className="link-no-style btn btn-outline">
              <Nav.Link as="span" eventKey="about-us" className="nav-text">
                About Us
              </Nav.Link>
            </AnchorLink>
            <NavDropdown.Divider />
            <AnchorLink to="/#services" className="link-no-style btn btn-outline">
              <Nav.Link as="span" eventKey="services" className="nav-text">
                Services
              </Nav.Link>  
            </AnchorLink>
            <NavDropdown.Divider />
           {/*} <AnchorLink to="/#industries" className="link-no-style btn btn-outline">

              <Nav.Link as="span" eventKey="industrial-standards" className="nav-text">
                Industries
              </Nav.Link>
            </AnchorLink>
  <NavDropdown.Divider />
            <AnchorLink to="/#associates" className="link-no-style btn btn-outline">

              <Nav.Link as="span" eventKey="legal-standards" className="nav-text">
                Associates
              </Nav.Link>
            </AnchorLink>
            <NavDropdown.Divider />

            */}
            <AnchorLink to="/#team" className="link-no-style btn btn-outline">

              <Nav.Link as="span" eventKey="team" className="nav-text">
                Team
              </Nav.Link>
            </AnchorLink>  
            <NavDropdown.Divider />
          
            <AnchorLink to="/#contact-us" className="link-no-style btn btn-outline">

              <Nav.Link as="span" eventKey="contact-us" className="nav-text">
                Contact Us
              </Nav.Link>
            </AnchorLink>
          </Nav>
        </Navbar.Collapse>

        {/* </Container> */}
      </Navbar>
   
    </>
  )
}

export default CustomNavbar
