/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container,ListGroup, Row, Col } from "react-bootstrap"
import { GlobalStyle } from "../utils/globalStyles"
import {Geo,Telephone,Envelope} from "react-bootstrap-icons"
import Header from "./header"
import Navbar from "./navBar"
import { AnchorLink } from "gatsby-plugin-anchor-links"

/*document.addEventListener('contextmenu', (e) => {
  if (e.target.tagName === 'IMG') {
    e.preventDefault()
    e.stopPropagation()
  }
})*/

const Layout = ({ children, pageInfo }) => (

  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main" style={{overfow:"hidden"}}>
         
         {children}


        </Container>

        <Container fluid className="px-0" >
        <Row style={{textAlign:"center",justifyContent:"center", alignItems:"center", fontSize:"20px", padding:"20px"}}> Copyright © {new Date().getFullYear()}, AIDIA Industrial Pty. Ltd</Row> 

        </Container>
    
      </>
    )}
  />
)

export default Layout
